import { animate, stagger } from "motion";

document.getElementById('current-year').textContent = new Date().getFullYear();

function textSplit(target) {
  target.innerHTML = target.textContent
    .split('')
    .map(char => `<span>${char}</span>`)
    .join('');
}

function animateTextOnHover(element) {
  element.addEventListener('mouseenter', () => {
    const spans = element.querySelectorAll('span');
    spans.forEach(span => {
      const randomX = (Math.random() - 0.5) * 200;
      const randomY = (Math.random() - 0.5) * 200;
      const randomZ = (Math.random() - 0.5) * 200;
      const randomRotateX = (Math.random() - 0.5) * 720;
      const randomRotateY = (Math.random() - 0.5) * 720;

      animate(span, {
        opacity: [1, 0],
        transform: [
          'perspective(2000px) translate(0, 0) rotate(0)',
          `perspective(2000px) translate(${randomX}px, ${randomY}px) translateZ(${randomZ}px) rotateX(${randomRotateX}deg) rotateY(${randomRotateY}deg)`
        ],
      }, {
        duration: 1,
        easing: 'ease-in-out',
      });
    });
  });

  element.addEventListener('mouseleave', () => {
    const spans = element.querySelectorAll('span');
    spans.forEach(span => {
      animate(span, {
        opacity: [0, 1],
        transform: [
          `perspective(2000px) translate(${(Math.random() - 0.5) * 200}px, ${(Math.random() - 0.5) * 200}px) translateZ(${(Math.random() - 0.5) * 200}px) rotateX(${(Math.random() - 0.5) * 720}deg) rotateY(${(Math.random() - 0.5) * 720}deg)`,
          'perspective(2000px) translate(0, 0) rotate(0)',
        ],
      }, {
        duration: 1,
        easing: 'ease-in-out',
      });
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const headerText = document.getElementById('js-header-text');
  if (headerText) {
    textSplit(headerText);
    animateTextOnHover(headerText);
  }

  const navItems = document.querySelectorAll('.nav-items a');
  navItems.forEach(navItem => {
    textSplit(navItem);
    animateTextOnHover(navItem);
  });
});
